import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3530204752/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ZealyQueryProvider","queryClient"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/app/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BraveShieldDialog"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/BraveShieldDialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/DatadogInit/DatadogInit.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/InitRewardful/InitRewardful.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextIntlProvider"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/NextIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SourceTracker"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/SourceTracker/SourceTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/components/Toaster/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthErrorProvider"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/AuthError.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommunityLayoutVariantProvider"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/CommunityLayoutVariant.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/CookiePreferences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/FeatureFlags/index.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/ReCaptcha.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/context/SupportChat/SupportChat.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/features/Popups/PopupProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3530204752/src/web-app/packages/webapp/styles/globals.css");
